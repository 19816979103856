<template>
  <div>
    <header class="search-top">
      <aside>
        <el-select v-model="first" placeholder="请选择一级分类" filterable @change="getCategory(first,1)">
          <el-option v-for="item in firstList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="second" placeholder="请选择二级分类" filterable @change="getCategory(second,2)">
          <el-option v-for="item in secondList" :key="'sec-'+item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="params.platform_category_id" filterable placeholder="请选择三级分类" @change="handle">
          <el-option v-for="item in thirdList" :key="'third'+item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
      </aside>
      <aside>
        <div class="grant-input">
          <div>导入</div>
          <input ref="file" type="file" @change="getFile" />
        </div>
        <el-button type="primary" @click="exCtl">导出</el-button>
        <el-button v-if="flag" type="primary" @click="create(params)">编辑</el-button>
      </aside>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #list="{row}">
        <span v-for="(item,i) in row.relation_site_category_name" :key="item.id">{{item.name}}{{i == row.relation_site_category_name.length-1 ? '': '、'}}</span>
      </template>
      <template #status="{row}">{{row.relation_status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span class="word-color red" @click="ctl(row,'del')">删除</span>
          <span class="word-color" v-if="row.relation_status == 1" @click="ctl(row,'stop')">停用</span>
          <span class="word-color" v-else @click="ctl(row,'play')">启用</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="visible" width="700px">
      <h2>编辑映射分类</h2>
      <section class="item">
        <aside>站内分类：</aside>
        <span>{{obj.typeName}}</span>
      </section>
      <section class="item">
        <aside>原站分类：</aside>
        <category-table :info="obj" @change="getCategoryInfo"></category-table>
      </section>
      <footer>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, watch } from '@vue/composition-api'
import CategoryTable from "../goodsManager/components/category-table.vue";

export default defineComponent({
  components: { CategoryTable },
  setup(props,{ root, refs }) {
    const visible = ref(false)
    const flag = ref(false)
    const str = ref('')
    const params = reactive({
      platform_category_id: undefined,
    })
    const first = ref('')
    const second = ref('')
    const header = [
      { name: "三级分类", value: "platform_category_name", width: 200 },
      { name: "对应原站", value: "site_name", width: 200 },
      { name: "对应分类", slot: 'list' },
      { name: "状态", value: "status", slot: 'status' ,width: 60 },
      { name: "操作", slot: 'control', width: 140 },
    ]
    const list = reactive({data: []})
    const firstList = ref([])
    const secondList = ref([])
    const thirdList = ref([])
    let obj = reactive({
      typeName: '',
      category_platform_id: undefined,
      site_category_cancel_ids: [],
      site_category_bind_ids: []
    })
      watch(visible,val=>{
        if(!val) {
          Object.assign(obj,{
            site_category_cancel_ids: [],
            site_category_bind_ids: []
          })
        }
      })
    const getList = () => {
      if(!params.platform_category_id) {
        root.$message.warning('请选择完分类后查询')
        return
      }
      root.$axios.get(`/category/site/mapping/list`, {
        params
      })
      .then(res=>{
        list.data = res.data.result
        flag.value = true
      })
      
    }
    const getCategory = (num,type) => {
      list.data = []
      flag.value = false
      if(!num && num !== 0) return
      root.$axios.get(`/category/platform/screenList`,{
        params: {
          p_id: num
        }
      })
      .then(res=>{
        if(type == 0) {
          firstList.value = res.data
          second.value = ''
          params.platform_category_id = undefined
          secondList.value = []
          thirdList.value = []
        } else if(type == 1) {
          secondList.value = res.data
          thirdList.value = []
          params.platform_category_id = undefined
        } else if(type == 2) {
          thirdList.value = res.data
          params.platform_category_id = undefined
        }
      })
      
    }
    getCategory(0,0)
    const create = () => {
      obj.typeName = str.value
      visible.value = true
    }
    const handle = (num)=>{
      str.value = ''
      list.data = []
      flag.value = false
      obj.category_platform_id = num
      firstList.value.forEach(item=>{
        if(item.category_platform_product_id == first.value) {
          str.value+=item.name+'/'
        }
      })
      secondList.value.forEach(item=>{
        if(item.category_platform_product_id == second.value) {
          str.value+=item.name+'/'
        }
      })
      thirdList.value.forEach(item=>{
        if(item.category_platform_product_id == num) {
          str.value+=item.name
        }
      })
    }
    const ctl = async (data,type)=>{
      let str = ''
      let url = '/category/site/mapping/update'
      let status = null
      switch(type){
        case 'del': str = '确定要删除吗？'
        url = '/category/site/mapping/delete'
        break;
        case 'stop': str = '确定要停用吗？'
        status = 0
        break;
        case 'play': str = '确定要启用吗？'
        status = 1
        break;
      }
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        status,
        category_site_product_id: data.category_site_product_id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const cancel = () => {
      visible.value = false
    }
    const confirm = () => {
      root.$axios.post(`/category/mapping/relation/update`, {
        category_platform_id: obj.category_platform_id,
        site_category_cancel_ids: obj.site_category_cancel_ids.join(','),
        site_category_bind_ids: obj.site_category_bind_ids.join(',')
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        cancel()
      })
      
    }
    const getCategoryInfo = data=>{
      Object.assign(obj,data)
    }
    const getFile = () => {
      const file = refs.file.files[0];
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('excel_file', file)
      root.$axios.post('/category/site/import',formData,config)
      .then(res=>{
        root.$message.success(res.msg)
        // getList()
      })
      
    };
    const exCtl = () => {
    root.$axios.get(`/category/site/export`)
      .then(res=>{
        const a = window.document.createElement('a')
        a.href= root.$store.state.CMS.url+res.data.file_path
        a.click()
      })
      
    }
    return {
      flag,
      params,
      list,
      header,
      first,
      second,
      firstList,
      secondList,
      thirdList,
      visible,
      obj,
      getList,
      getCategory,
      ctl,
      create,
      handle,
      cancel,
      confirm,
      exCtl,
      getCategoryInfo,
      getFile
    }
  },
})
</script>
<style lang="scss" scoped>
.search-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  aside{
    &>div{
      margin-right: 15px;
    }
  }
}
.grant-input {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 84px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #7a7c81;
  line-height: 32px;
  margin-right: 15px;
  background-color: $mainColor;
  div {
    font-size: 14px;
    color: #fff;
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
.item{
  display: flex;
  align-items: center;
  margin-top: 15px;
  &>aside{
    word-spacing: normal;
    white-space: nowrap;
  }
}
.upload-img{
  height: 150px;
  margin-top: 15px;
}
footer{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.control{
  display: flex;
  span{
    margin-right: 15px;
  }
}
</style>